import moment from 'moment';

const continentMap = {
  'Europe': ['Spain'],
  'North America': ['United States', 'Puerto Rico'],
  'Mexico': ['Mexico'],
  'South America': ['Colombia']
};

function getCountryContinent(country) {
  return Object.keys(continentMap).find(continent => continentMap[continent].includes(country));
}

export default function tours() {
  var tour_id = $('#tour').data('tour-id');

  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_' + tour_id + '/events?app_id=45PRESS_camilo',
    method: 'GET',
    dataType: 'json',
    error: () => {
      // alert('Error fetching events!');
    },
    success: data => {
      console.log('data', data);

      const countrySelector = $('.tour-country-selector');
      let selectorHtml = '<button class="country-selector" data-continent="all">All</button>';
      const uniqueContinent = new Set();


      let currentCountry = null

      data.forEach(event => {

        currentCountry = getCountryContinent(event.venue.country)

        if (!currentCountry) {
          console.log('No country found from:', event.venue.country)
        } else {
          uniqueContinent.add(currentCountry);
        }
      });

      uniqueContinent.forEach(continent => {
        selectorHtml += `<button class="country-selector" data-continent="${continent}">${continent}</button>`;
      });

      countrySelector.html(selectorHtml);

      const events = $('.tour-dates');
      let html = '';

      if (data.length) {

        for (let event of data) {
          let location = event.venue.location;
          html += `<div class="tour-date" data-continent="${getCountryContinent(event.venue.country)}">`;
          html += '<div class="tour-info">';
          html += '<div class="date">';
          html += moment(event.datetime).format('ddd, MMM D');
          html += '</div>';
          html += '<div class="venue">' + event.venue.name + '</div>';
          html += '<div class="location">' + location + '</div>';
          html += '</div>';
          html += '<div class="tour-action">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank">' + offer.type + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('No upcoming events.');
      }

      $('.country-selector').on('click', function () {
        const selectedContinent = $(this).data('continent');

        $('.tour-date').each(function () {
          if (selectedContinent === 'all' || selectedContinent === $(this).data('continent')) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });

        $(".tour-dates").animate({ scrollTop: 0 }, "slow");
      });

    }
  });
}
